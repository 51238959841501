<template>
  <section class="error-page">
    <div class="container overflow-hidden mb-5">
      <div
        class="
          error-page-container
          w-100
          h-100
          d-flex
          aligns-items-center
          justify-content-center
        "
      >
        <span class="error-number d-inline">4</span>
        <img src="@/assets/images/surprise.svg" class="d-inline-block" />
        <span class="error-number d-inline-block">4</span>
      </div>
      <div class="text-center">
        <p>Uğursuz əməliyyat. Zəhmət olmasa yenidən cəhd edin.</p>
        <router-link
          to="/"
          class="
            btn-default btn-blue
            text-decoration-none text-white
            d-inline-block
            px-5
            py-3
          "
        >
          Ana səhifəyə qayıt
        </router-link>
      </div>
    </div>
  </section>
</template>